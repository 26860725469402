export const routes = {
  tools: {
    portfolio: '/tools/portfolio',
    portfolio_details: (coin: string): string => `/tools/portfolio/${coin}`,
    defi_tracker: '/tools/defi_tracker',
    nft_tracker: '/tools/nft_tracker',
    dca: '/tools/dca',
    wallet: '/tools/portfolio/wallet',
    radar: '/tools/radar',
    news: '/tools/news',
    radar_register_filter: '/tools/radar/filters/create',
    radar_update_filter: (id: string): string => `/tools/radar/filters/${id}`,
    social_media_details: (id: string): string => `/tools/social-media/${id}`,
    social_media_influencers: (id: string, coinLogo: string): string =>
      `/tools/social-media/${id}/influencers?coinLogo=${coinLogo}`,
    social_media: '/tools/social-media',
    calendar: '/tools/calendar',
  },
  analysis: {
    overview: '/analysis/overview',
    performance: '/analysis/performance',
    trend: '/analysis/trend',
    rsi: '/analysis/rsi',
    volumeDeAtencao: '/analysis/volume-de-atencao',
    supportAndResistance: '/analysis/support-resistance',
    sma: {
      root: '/analysis/sma',
      priceCrossSma: '/analysis/sma/price-cross',
      cross5: '/analysis/sma/cross-5',
      cross10: '/analysis/sma/cross-10',
      cross20: '/analysis/sma/cross-20',
      cross30: '/analysis/sma/cross-30',
      cross50: '/analysis/sma/cross-50',
      cross100: '/analysis/sma/cross-100',
    },
    ema: {
      root: '/analysis/ema',
      priceCrossEma: '/analysis/ema/price-cross',
      cross9: '/analysis/ema/cross-9',
      cross12: '/analysis/ema/cross-12',
      cross26: '/analysis/ema/cross-26',
      cross50: '/analysis/ema/cross-50',
      cross100: '/analysis/ema/cross-100',
    },
    macd: '/analysis/macd-signal',
    stotch: '/analysis/stotch',
    bollinger: '/analysis/bollinger',
    ath: '/analysis/ath',
    superTrend: '/analysis/supertrend',
    altcoinsXBtc: '/analysis/altcoins-x-btc',
    fibonacci: '/analysis/fibonacci',
    rsiDivergence: '/analysis/rsi-divergence',
    googleTrends: '/analysis/google-trends',
    settlementMap: '/analysis/settlement-map',
    cmeGaps: '/analysis/cme-gaps',
    mvrvZScore: '/analysis/mvrv-z-score',
    // superFilter: '/analysis/super-filter',
  },
  exta: {
    halving: '/extra/halving',
    fear_and_greed: '/extra/fear_and_greed',
    biggests_hights: '/extra/biggests_hights',
    biggests_falls: '/extra/biggests_falls',
  },
  subscription: '/subscription',
  auth: '/',
  forgot_password: '/forgot-password',
  recover_password: '/recover-password',
  create_account: '/signup',
};
