import { VscGraphLine } from 'react-icons/vsc';
import {
  TbAntennaBars3,
  TbChartBubbleFilled,
  TbCircuitResistor,
  TbClockDollar,
  TbDeviceIpadPlus,
  TbTargetArrow,
} from 'react-icons/tb';
import {
  RiBarChartHorizontalLine,
  RiBitCoinFill,
  RiLayoutGridFill,
} from 'react-icons/ri';
import { PiDotsThreeOutlineVerticalBold } from 'react-icons/pi';
import {
  MdAnalytics,
  MdBlurLinear,
  MdEvent,
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalRight,
  MdSsidChart,
} from 'react-icons/md';
import {
  LuAlignHorizontalDistributeCenter,
  LuAlignHorizontalSpaceAround,
} from 'react-icons/lu';
import { LiaChartLineSolid } from 'react-icons/lia';
import { IoShareSocial } from 'react-icons/io5';
import { IoMdMegaphone, IoMdTrendingUp } from 'react-icons/io';
import { GiCornerExplosion, GiMountaintop, GiRadarSweep } from 'react-icons/gi';
import { FaImage, FaRobot, FaTemperatureHigh, FaBurn } from 'react-icons/fa';
import { FaChartLine, FaMountain } from 'react-icons/fa';
import { CgPerformance } from 'react-icons/cg';
import { BsClipboard2DataFill } from 'react-icons/bs';
import { BiPieChartAlt } from 'react-icons/bi';
import { AiOutlineFall } from 'react-icons/ai';

import { routes } from './routes';
import { ISidebarData } from '../interfaces/i-sidebar-data';

export const sidebar: ISidebarData[] = [
  {
    tag: 'tools',
    label: 'Ferramentas',
    icon: RiLayoutGridFill,
    children: [
      {
        tag: 'portfolio',
        label: 'Portfólio',
        route: routes.tools.portfolio,
        icon: BiPieChartAlt,
      },
      // {
      //   tag: 'defi-tracker',
      //   label: 'DeFi Tracker',
      //   route: routes.tools.defi_tracker,
      //   icon: TbTargetArrow,
      // },
      // {
      //   tag: 'nft-tracker',
      //   label: 'NFT Tracker',
      //   route: routes.tools.nft_tracker,
      //   icon: FaImage,
      // },
      {
        tag: 'dca',
        label: 'DCA',
        route: routes.tools.dca,
        icon: FaRobot,
      },
      {
        tag: 'radar',
        label: 'Radar',
        route: routes.tools.radar,
        icon: GiRadarSweep,
        platinum: true,
      },
      {
        tag: 'social-media',
        label: 'Social Media',
        route: routes.tools.social_media,
        icon: IoShareSocial,
        platinum: true,
      },
      {
        tag: 'calendar',
        label: 'Calendário',
        route: routes.tools.calendar,
        icon: MdEvent,
      },
      {
        tag: 'news',
        label: 'Notícias',
        route: routes.tools.news,
        icon: IoMdMegaphone,
        platinum: true,
        isNew: true,
      },
    ],
  },
  {
    tag: 'analysis',
    label: 'Análises',
    icon: MdAnalytics,
    children: [
      {
        tag: 'overview',
        label: 'Visão Geral',
        route: routes.analysis.overview,
        icon: BsClipboard2DataFill,
      },
      {
        tag: 'performance',
        label: 'Performance',
        route: routes.analysis.performance,
        icon: CgPerformance,
      },
      {
        tag: 'trend',
        label: 'Tendência',
        route: routes.analysis.trend,
        icon: IoMdTrendingUp,
      },
      {
        tag: 'volume-de-atencao',
        label: 'Volume de atenção',
        route: routes.analysis.volumeDeAtencao,
        icon: GiCornerExplosion,
      },
      {
        tag: 'rsi',
        label: 'RSI',
        route: routes.analysis.rsi,
        icon: VscGraphLine,
      },
      {
        tag: 'supertrend',
        label: 'SuperTrend',
        route: routes.analysis.superTrend,
        icon: IoMdTrendingUp,
      },
      {
        tag: 'altcoins-x-btc',
        label: 'Altcoins X BTC',
        route: routes.analysis.altcoinsXBtc,
        icon: RiBitCoinFill,
      },
      {
        tag: 'sma',
        label: 'Grupo SMA',
        icon: MdOutlineAlignHorizontalRight,
        subchildren: [
          {
            tag: 'sma',
            label: 'SMA',
            route: routes.analysis.sma.root,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-price-cross',
            label: 'Price Cross SMA',
            route: routes.analysis.sma.priceCrossSma,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-cross-5',
            label: 'SMA 5 Cross',
            route: routes.analysis.sma.cross5,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-cross-10',
            label: 'SMA 10 Cross',
            route: routes.analysis.sma.cross10,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-cross-20',
            label: 'SMA 20 Cross',
            route: routes.analysis.sma.cross20,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-cross-30',
            label: 'SMA 30 Cross',
            route: routes.analysis.sma.cross30,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-cross-50',
            label: 'SMA 50 Cross',
            route: routes.analysis.sma.cross50,
            icon: MdOutlineAlignHorizontalRight,
          },
          {
            tag: 'sma-cross-100',
            label: 'SMA 100 Cross',
            route: routes.analysis.sma.cross100,
            icon: MdOutlineAlignHorizontalRight,
          },
        ],
      },
      {
        tag: 'ema',
        label: 'Grupo EMA',
        icon: MdOutlineAlignHorizontalLeft,
        subchildren: [
          {
            tag: 'ema',
            label: 'EMA',
            icon: MdOutlineAlignHorizontalLeft,
            route: routes.analysis.ema.root,
          },
          {
            tag: 'ema-price-cross',
            label: 'Price Cross EMA',
            route: routes.analysis.ema.priceCrossEma,
            icon: MdOutlineAlignHorizontalLeft,
          },
          {
            tag: 'ema-cross-9',
            label: 'EMA 9 Cross',
            route: routes.analysis.ema.cross9,
            icon: MdOutlineAlignHorizontalLeft,
          },
          {
            tag: 'ema-cross-12',
            label: 'EMA 12 Cross',
            route: routes.analysis.ema.cross12,
            icon: MdOutlineAlignHorizontalLeft,
          },
          {
            tag: 'ema-cross-26',
            label: 'EMA 26 Cross',
            route: routes.analysis.ema.cross26,
            icon: MdOutlineAlignHorizontalLeft,
          },
          {
            tag: 'ema-cross-50',
            label: 'EMA 50 Cross',
            route: routes.analysis.ema.cross50,
            icon: MdOutlineAlignHorizontalLeft,
          },
          {
            tag: 'ema-cross-100',
            label: 'EMA 100 Cross',
            route: routes.analysis.ema.cross100,
            icon: MdOutlineAlignHorizontalLeft,
          },
        ],
      },
      {
        tag: 'macd-signal',
        label: 'MACD Signal',
        route: routes.analysis.macd,
        icon: TbAntennaBars3,
      },
      {
        tag: 'stotch',
        label: 'Stoch',
        route: routes.analysis.stotch,
        icon: LiaChartLineSolid,
      },
      {
        tag: 'bollinger',
        label: 'Bollinger',
        route: routes.analysis.bollinger,
        icon: MdBlurLinear,
      },
      {
        tag: 'ath',
        label: 'ATH',
        route: routes.analysis.ath,
        icon: FaMountain,
      },
      {
        tag: 'support-resistance',
        label: 'S/R Níveis',
        route: routes.analysis.supportAndResistance,
        icon: TbCircuitResistor,
      },
      // {
      //   tag: 'mvrv-z-score',
      //   label: 'MVRV Z Score',
      //   route: routes.analysis.mvrvZScore,
      //   icon: GiMountaintop,
      //   isNew: true,
      // },
    ],
  },
  // {
  //   tag: 'extra',
  //   label: 'Extra',
  //   icon: TbDeviceIpadPlus,
  //   children: [
  //     {
  //       tag: 'halving',
  //       label: 'Halving',
  //       route: routes.exta.halving,
  //       icon: TbClockDollar,
  //     },
  //     {
  //       tag: 'fear_and_greed',
  //       label: 'Fear and Greed',
  //       route: routes.exta.fear_and_greed,
  //       icon: FaTemperatureHigh,
  //     },
  //     {
  //       tag: 'biggest_hights',
  //       label: 'Maiores altas',
  //       route: routes.exta.biggests_hights,
  //       icon: FaBurn,
  //     },
  //     {
  //       tag: 'biggest_falls',
  //       label: 'Maiores quedas',
  //       route: routes.exta.biggests_falls,
  //       icon: AiOutlineFall,
  //     },
  //   ],
  // },
];
